.top-navbar {
  padding: 10px 25px;

  .toggle-btn {
    padding: 5px;
    background: var(--light);
    border-radius: 4px;
    box-shadow: 1px 1px 10px rgba($color: #000000, $alpha: 0.04);

    &:hover {
      background: var(--primary);
      color: var(--light);
    }

    svg {
      width: 25px;
    }
  }

  .profile {
    position: relative;

    svg {
      width: 35px;
    }

    &-dropdown {
      position: absolute;
      display: none;
      right: 0;
      width: 215px;
      background: var(--light);
      padding: 10px;
      border-radius: 5px;
      border: 1px solid rgba($color: #000000, $alpha: 0.1);
      box-shadow: 5px 5px 10px rgba($color: #000000, $alpha: 0.05);

      ul {
        padding: 0;
        margin: 0;

        li {
          list-style: none;
          padding: 10px 15px;
          transition: background 100ms ease-in;
          cursor: pointer;
          border-radius: 5px;

          &:hover {
            background-color: rgba($color: #000000, $alpha: 0.05);
          }

          a {
            color: var(--dark);
            text-decoration: none;
          }

          .auth {
            svg {
              width: 20px;
            }
          }
        }
      }
    }

    &:is(:hover, :focus, :target) .profile-dropdown {
      display: block;
    }

    // dropdown
    .profile-dropdown {
      z-index: 1;
    }
  }
}