:root {
  --primary: #030359;
  --primary-hover: #04047e;
  --blue: #1863d3;
  --purple: #4d17bd;
  --lavender: #e9eaff;
  --isabelline: #f5ebef;
  --magnolia: #f9ecff;
  --light-blue: #1f77cf;
  --blue-green: #1e95af;
  --light: #fff;
  --off-white: #f4f7ff;
  --dark: #000;
  --grey: rgba(141, 136, 136, 0.733);

  /* WIDTHS */
  --sidebar: 270px;
  --menu-indicator-width: 42px;

  /* SITE TITLE */
  --title: 26px;
  --title-color: var(--primary);
  --title-weight: 900;

  /* border radius */
  --border-radius-1: 0.3rem;
  --border-radius-2: 12px;
  --border-radius-3: 20px;

  /* transitions */
  --transition: all 0.2s;
}

body {
  background-color: var(--off-white) !important;
}

/* changing scrollbar */
/* width */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--grey);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-hover);
}

/* changing scrollbar  end*/


/* borders */
.red {
  border: 1px solid red;
}

.black {
  border: 1px solid blue;
}

/* custom links setting */
.primary-link {
  color: var(--primary);
  transition: var(--transition);
}

.primary-link:hover {
  color: var(--purple);
}

/* font sizes */
.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.btn-primary {
  --bs-btn-bg: var(--primary) !important;
  --bs-btn-border-color: var(--primary) !important;
  --bs-btn-hover-bg: var(--primary-hover) !important;
}

@media screen and (max-width: 992px) {
  :root {
    --sidebar: 70px;
  }

  body.sidebar-open::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
}