.form-input {
  padding: 10px !important;
}
.py-6 {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

// focus input
.form-control:focus {
  border-color: var(--purple) !important;
}

.form-control::-webkit-input-placeholder {
  vertical-align: middle !important;
}

.custom-pagination {
  width: 75px;
}
