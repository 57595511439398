.custom-checkbox {
  .form-check-input {
    width: 20px !important;
    height: 20px !important;
    box-shadow: none !important;
    margin-top: 2px !important;
    margin-right: 8px !important;
    border-radius: var(--border-radius-1) !important;
    cursor: pointer;

    &:checked {
      background-color: var(--primary);
      border-color: var(--primary);
    }
  }

  // Label
  .form-check-label {
    font-size: 14px !important;
  }
}