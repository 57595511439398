/* Table.css */

.table-container {
  overflow-x: auto;

  /* Enable horizontal scrolling */
  th {
    white-space: nowrap;
    vertical-align: middle;

    span {
      display: inline-block;
    }
  }

  th,
  td {
    vertical-align: middle;
  }

  .deletebtn {
    --bs-btn-bg: transparent;
    --bs-btn-color: rgb(190, 32, 32);
    --bs-btn-active-bg: transparent;
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-color: hsl(0, 98%, 51%);
    outline: none;
    --bs-btn-border-color: none;
    padding: 0;
    text-align: center;
    width: 100%;
    margin: auto 0;
    // transform: translateY(45%);

    svg {
      width: 22px;
    }
  }
}


/* Define different styles for different screen sizes */
@media (max-width: 768px) {

  /* Apply styles for small screens */
  .table-container {
    max-width: 100%;

    th {
      min-width: 170px;
    }
  }
}

// @media (min-width: 1920px) {
//   /* Apply styles for large screens */
//   .table-container {
//     max-width: 60%;
//   }
// }