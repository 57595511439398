.icon-card {
  position: relative;
  overflow: hidden;
  min-height: 175px;
  min-width: 300px;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 10px 10px 50px rgba($color: #000000, $alpha: 0.2);
  background-color: var(--bg);
  color: var(--light);
  transition: transform 300ms ease-in-out;
  cursor: default;
  @media screen and (max-width: 400px) {
    min-width: 100%;
  }
  &:hover {
    transform: scale(1.01);
  }
  @media screen and (min-width: 375px) {
    .content {
      min-width: 250px;
      margin: 0 auto;
    }
  }
  .icon {
    svg {
      --size: 50px;
      width: var(--size);
      height: var(--size);
    }
  }
  .title {
    color: var(--light);
    margin: 0;
    font-weight: normal;
    font-size: 18px;
    .small-title {
      opacity: 0.7;
      font-size: 12px;
    }
  }
  .subtitle {
    font-size: 14px;
    margin: 0;
    opacity: 0.7;
  }
  .circle {
    --size: 70px;
    position: absolute;
    top: -20px;
    right: -20px;
    width: var(--size);
    height: var(--size);
    opacity: 0.2;
    z-index: 0;
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 50px;
      padding: 10px;
      background: linear-gradient(var(--bg), var(--light));
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
    &:nth-child(2) {
      top: calc(100% - 50px);
      left: -20px;
      &::before {
        background: linear-gradient(200deg, var(--light), var(--bg) 50%);
      }
    }
  }
}
