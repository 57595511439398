.files-list {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    border-radius: 5px;
    transition: background 300ms ease-in-out;

    &:hover {
      background-color: var(--lavender);
    }
    .file-name {
      font-size: 18px;
    }
    .file-size {
      font-size: 12px;
      color: rgba($color: #000000, $alpha: 0.5);
    }
    .btn-cross {
      padding: 0;
      svg {
        --size: 25px;
        width: var(--size);
        height: var(--size);
      }
    }
  }
}
